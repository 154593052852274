<template>
  <div
    class="message-component"
    :class="`message-${messageType} ${[classes.join(' ')]}`"
  >
    <p>{{ message }}</p>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
    },
    messageType: {
      type: String,
      default: 'success',
    },
    classes: {
      type: Array,
    },
  },
};
</script>
