<template>
  <div>
    
    <form
      class="mt-1 d-flex align-items-center flex-column"
      @submit.prevent="handleSubmit"
    >
      <div class="form-control w-100">
        <input
          placeholder="Ruc"
          id="ruc"
          class="font_helvetica_neue55_roman"
          type="number"
          name="ruc"
          v-model.number="userForm.ruc"
          required
        />
      </div>
      <LoadingComponent v-if="isLoading" />
      <MessageComponent
        v-if="!isLoading && messageForm.message"
        :message="messageForm.message"
        :messageType="messageForm.messageType"
        :classes="['text-center', 'mt-1', 'form-message', 'message-big']"
      />

      <div class="buttons mt-1">
        <button class="btn-secondary" type="submit">Enviar</button>
      </div>
    </form>
  </div>
</template>
<script>
import { LoadingComponent, MessageComponent } from "@/components";
import useForgotPassword from "@/modules/auth/composables/forgot-password/useForgotPassword";

export default {
  components: {
    LoadingComponent,
    MessageComponent,
  },
  props: {
    showField: {
      type: String,
      default: "email",
    },
  },

  setup() {
    return {
      ...useForgotPassword(),
    };
  },
};
</script>
