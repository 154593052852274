import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const useAuthentication = () => {
  const store = useStore();
  const router = useRouter();

  const validateAuthentication = async (name = 'login') => {
    const { status, rol } = await store.dispatch('auth/checkAuthentication');

    if (status && rol === '1') {
      router.push({ name: 'homeAdmin' });
    } else if (status && (rol === '2' || rol === '3')) {
      router.push({ name: 'dashboard-billing' });
    } else {
      router.push({ name });
    }
  };

  return {
    validateAuthentication,
  };
};

export default useAuthentication;
