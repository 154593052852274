<template>
  <div class="container-loading" >
    <div class="text-center">
      <div class="loading" :class="`loading-${loadingType}`"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loadingType: {
      type: String,
      default: 'secondary',
    },
  },
};
</script>
