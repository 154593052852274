<template>
  <div class="form-wrapper-container">
    <div class="form form-col-1 mt-2 mb-2">
      <div class="form-content text-center">
        <div class="header-images">
          <div class="logo">
            <img
              src="images/logos/logo-faber.png"
              alt="Faber Castell"
              title="Faber Castell"
            />
          </div>
          <div class="featured">
            <img
              src="images/decorations/forgot-password_.png"
              alt="Email éxitoso"
              title="Email éxitoso"
            />
          </div>
        </div>
        <div class="body mx-sm-3">
          <h1 class="mb-1 font_helvetica_neue75_bold">
            ¿Olvidaste tu contraseña?
          </h1>
          <p class="font_helvetica_neue55_roman">
            Recuerda que puedes recuperarla usando tu RUC
          </p>
        </div>

        <Form />
        <div class="mt-2 font_helvetica_neue75_roman">
          <p>
            ¿Recordaste tu contraseña?
            <router-link
              class="font_helvetica_neue75_bold d-md-block"
              :to="{ name: 'login' }"
              >Iniciar Sesión</router-link
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Form from './forms/ForgotPasswordForm.vue';
import { onBeforeMount } from 'vue';
import useAuthentication from '@/modules/auth/composables/authentication/useAuthentication';

export default {
  components: {
    Form,
  },
  setup() {
    onBeforeMount(() => {
      const { validateAuthentication } = useAuthentication();
      validateAuthentication('forgot-password');
    });
  },
};
</script>
